/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import {
  Grid,
  Tab,
  Tabs,
  Box,
  FormControlLabel,
  Paper,
  Typography,
  Divider,
  TextField,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { SnackbarProvider } from 'notistack';
import NumberFormat from 'react-number-format';
import {
  CustomInput,
  Toaster,
  Loading,
  CustomSwitch,
  CustomModal,
} from '../../../components';
import { Rules } from '../../../helpers/RHFRules';
import { ActionPopUp } from '../../../Layouts';
import TabPanel from '../components/TabPanel';
import {
  errorAlert, PRODUCT_ID, successAlert,
} from '../constants';
import DocumentsTab from '../components/documentsTab';
import getDocumentsByProduct from '../../../services/configDocuments/getDocumentsByProduct';
import updateConfigDocuments from '../../../services/configDocuments/postConfigDocuments';
import { Button } from './styles';

const ActionForm = ({
  open, title, defaultValues, onClose, product,
}) => {
  const [alert, setAlert] = useState({ status: false, type: '', message: '' });
  const [loading, setLoading] = useState(false);
  const [tabSelected, setTabSelected] = useState(0);
  const [documents, setDocuments] = useState([]);
  const [screens, setScreens] = useState([]);
  const [screensData, setScreensData] = useState(null);
  const [canEdit, setCanEdit] = useState(false);
  const [isObligatory, setIsObligatory] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [screensBackup, setScreensBackup] = useState({});

  const productName = PRODUCT_ID[product] || '';
  const fetchDocuments = async () => {
    setLoading(true);
    if (productName !== '') {
      try {
        const { data: { documents: documentsData, screens: screensInfo }, status } = await getDocumentsByProduct(productName);
        if (status === 200) {
          setDocuments(documentsData);
          setScreensData(screensInfo);
        } else {
          setDocuments([]);
          setScreensData([]);
          if (status !== 404) setAlert(errorAlert);
        }
      } catch (error) {
        setAlert(errorAlert);
      } finally {
        setLoading(false);
      }
    }
  };
  const providerRef = useRef();
  const {
    handleSubmit,
    control,
    reset,
    errors,
    trigger,
    getValues,
    formState: { isValid },
  } = useForm({ defaultValues, mode: 'onChange' });

  const handleOnclose = () => {
    onClose();
  };

  const saveChanges = async () => {
    try {
      const payload = {
        producto: productName,
        documents,
        screens: screensData,
      };
      await updateConfigDocuments(payload);
      setAlert(successAlert);
    } catch (error) {
      setAlert(errorAlert);
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    setLoading(true);
    if (tabSelected === 0) {
      await saveChanges();
    } else {
      console.log('save');
      setLoading(false);
    }
  };

  const handleAdd = () => {
    trigger();
    if (!isValid) {
      return;
    }
    const { order, title: screenTitle } = getValues();
    const orderRepeated = screens.find((screen) => screen.order === order);
    if (orderRepeated) {
      setShowDialog(true);
      return;
    }
    const id = Date.now().toString();

    const screen = {
      id,
      order,
      title: screenTitle,
      isObligatory,
    };

    setScreensBackup((prev) => ({ ...prev, [id]: order }));
    const newScreens = [...screens, screen];
    const sortedScreens = newScreens.sort((prev, next) => prev.order - next.order);
    setScreens(sortedScreens);
    reset(defaultValues);
    setIsObligatory(false);
  };

  const handleDelete = (id) => {
    const filteredScreens = screens.filter((screen) => screen.id !== id);
    setScreens(filteredScreens);
  };

  const handleTabChange = (event, newValue) => {
    setTabSelected(newValue);
  };
  function a11yProps(index) {
    return {
      id: `scrollable-force-tab-${index}`,
      'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
  }

  useEffect(() => {
    reset(defaultValues);
  }, [open]);

  useEffect(() => {
    fetchDocuments();
  }, [product]);

  function NumberFormatCustom(props) {
    const {
      inputRef, onChange, name, ...other
    } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name,
              value: values.value,
            },
          });
        }}
      />
    );
  }

  return (
    <SnackbarProvider ref={providerRef}>
      {loading ? <Loading /> : null}
      <ActionPopUp
        open={open}
        title={title}
        onClose={handleOnclose}
        onSave={handleSubmit(handleSave)}
        enabledControls
      >
        <form noValidate autoComplete="off">
          <Grid
            container
            justifyContent="space-evenly"
            alignItems="stretch"
            spacing={2}
          >
            <Grid item xs={12}>
              <Tabs
                value={tabSelected}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                <Tab label="Documentos Existentes" {...a11yProps(0)} />
                <Tab label="Agregar Pantallas" {...a11yProps(1)} />
              </Tabs>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} />
            <Grid item xs={12} sm={12} md={4} lg={4} />
            <Grid item xs={12} sm={12} md={4} lg={4} />
            <Grid item xs={12}>
              {screensData !== null && (
              <DocumentsTab
                setAlert={setAlert}
                setLoading={setLoading}
                tabSelected={tabSelected}
                documents={documents}
                setDocuments={setDocuments}
                screens={screensData}
                setScreens={setScreensData}
              />
              )}
              <TabPanel value={tabSelected} index={1}>
                <Grid
                  container
                  justifyContent="space-evenly"
                  alignItems="stretch"
                  spacing={2}
                >
                  <Grid item xs={1}>
                    <CustomInput
                      label="Orden"
                      name="order"
                      type="number"
                      control={control}
                      rule={Rules.numerico}
                      error={errors}
                      inputProps={{
                        maxLength: 3,
                      }}
                    />
                  </Grid>
                  <Grid item xs={7}>
                    <CustomInput
                      label="Titulo"
                      name="title"
                      control={control}
                      rule={Rules.required}
                      error={errors}
                      inputProps={{
                        maxLength: 200,
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Paper variant="outlined">
                      <Box display="flex" style={{ paddingTop: 7, paddingBottom: 7 }} justifyContent="center">
                        <FormControlLabel
                          control={(
                            <CustomSwitch
                              checked={isObligatory}
                              onChange={(e) => {
                                setIsObligatory(
                                  e.target.checked,
                                );
                                if (!e.target.checked) {
                                  setIsObligatory((prev) => !prev);
                                }
                              }}
                              name="isObligatory"
                              color="primary"
                            />
                    )}
                          label="Obligatorio:"
                          labelPlacement="start"
                        />
                      </Box>
                    </Paper>
                  </Grid>
                  <Grid item xs={2}>
                    <Button type="button" variant="contained" onClick={handleAdd}>
                      Agregar
                    </Button>
                  </Grid>
                  { screens.length > 0 && (
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid container style={{ marginTop: 50 }}>
                        <Grid item xs={10}>
                          <Typography variant="h4" gutterBottom style={{ marginLeft: 10 }}>Pantallas</Typography>
                        </Grid>
                        <Grid item xs={2} justifyContent="flex-end">
                          <Button type="button" size="small" variant="outlined" onClick={() => { setCanEdit((prev) => !prev); }}>
                            {canEdit ? 'Actualizar' : 'Editar pantallas'}
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Typography>Orden</Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography>Titulo</Typography>
                      </Grid>
                    </Grid>
                    <Divider />
                    <Grid container spacing={3} style={{ marginTop: 20 }}>
                      {screens.map((item) => (
                        <Grid container key={`screen_${item.id}`} style={{ marginTop: 10, marginLeft: 10 }} alignItems="center">
                          <Grid item xs={3} key={`${item.id}_order`}>
                            <TextField
                              id={`order_input_${item.id}`}
                              variant="outlined"
                              value={screensBackup[item.id]}
                              type="number"
                              onChange={() => {}}
                              onBlur={() => {}}
                              name="numberformat"
                              disabled={!canEdit}
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                              }}
                            />
                          </Grid>
                          <Grid item xs={8}>
                            <Typography>{item.title}</Typography>
                          </Grid>
                          {canEdit && (
                          <Grid item xs={1} ml="auto">
                            <IconButton
                              edge="end"
                              aria-label="Editar"
                              size="small"
                              onClick={() => {}}
                            >
                              <Tooltip title="Editar" aria-label="edit" placement="top">
                                <EditIcon />
                              </Tooltip>
                            </IconButton>
                            <IconButton
                              edge="end"
                              aria-label="Delete"
                              size="small"
                              onClick={() => {
                                setShowDeleteDialog(true);
                                setItemToDelete(item.id);
                              }}
                              style={{ paddingLeft: 10 }}
                            >
                              <Tooltip title="Eliminar" aria-label="delete" placement="top">
                                <DeleteIcon />
                              </Tooltip>
                            </IconButton>
                          </Grid>
                          )}
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                  )}
                </Grid>
              </TabPanel>
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4} />
            <Grid item xs={12} sm={12} md={4} lg={4} />
          </Grid>
        </form>
        <CustomModal
          firstButtonAction={() => setShowDialog(false)}
          isOpen={showDialog}
          setIsOpen={setShowDialog}
          title="Orden de pantalla repetido"
          text="Escribe otro numero de orden en que se mostrará la pantalla"
          firstButtonLabel="Aceptar"
        />
        <CustomModal
          firstButtonAction={() => handleDelete(itemToDelete)}
          isOpen={showDeleteDialog}
          setIsOpen={setShowDeleteDialog}
          title="Borrar pantalla"
          text="¿Esta seguro que quiere borra la pantalla?"
          firstButtonLabel="Eliminar"
          secondButtonLabel="Cancelar"
          secondButtonAction={() => setShowDeleteDialog(false)}
        />
        <Toaster
          show={alert.status}
          type={alert.type}
          text={alert.message}
          onClose={() => setAlert({ ...alert, status: false })}
        />
      </ActionPopUp>
    </SnackbarProvider>
  );
};

ActionForm.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  product: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  defaultValues: PropTypes.object.isRequired,
};

export default ActionForm;
