const paddingLeft = '40px';

export const columns = [
  {
    id: 'name',
    label: 'Nombre del documento',
    paddingLeft,
    width: 150,
    position: 1,
  },
  {
    id: 'type',
    label: 'Tipo de documento',
    paddingLeft,
    width: 150,
    position: 2,
  },
  {
    id: 'actions',
    paddingLeft: '',
    label: 'Acciones',
    width: 5,
    position: 3,
  },
];

export const DOCUMENT_TYPES = {
  MULTIMEDIA: 'Multimedia',
  TEXT: 'Texto',
  MAP: 'Mapa',
  DOCUMENT: 'Documento',
};

export const DELETE_CONFIRM_MODAL_COPY = 'El documento que intentas borrar actualmente es requerido en la documentación de arraigo solicitada en los productos: Link de pago, BilleTap, Terminal Billet, en caso de eliminarlo ya no sería parte de las informaciones solicitadas al cliente.';
export const EDIT_CONFIRM_MODAL_COPY = 'Los cambios realizados en este documento serán reflejados inmediato';
