/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { Box, CircularProgress, Paper } from '@material-ui/core';
import { THEME } from '../../../../theme/colors';

export const ButtonStyled = styled.button`
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  min-width: 64px;
  border: none;
  text-transform: uppercase;
  padding: 6px 16px;
  line-height: 1.75;
  cursor: pointer;
  font-weight: bold;
  letter-spacing: 0.02857em;
  box-shadow: none;
  background-color: ${({ theme }) => theme.black5};
  border-radius: 5px;
  color: ${({ theme }) => theme.black};
`;

export const TextStyled = styled.p`
  text-transform: capitalize;
  font-size: 20px;
  color: ${({ theme }) => theme.black};
`;

export const StyledPaper = styled(Paper)`
  height: 40px;
  width: 175px;
`;

export const StyledBox = styled(Box)`
  padding-top: 10px;
`;

export const StyledInput = styled.input`
  display: none;
`;

export const CircularProgressStyled = styled(CircularProgress)`
  color: ${THEME.primary};
`;
export const ImgStyled = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.2);
  padding: 16px 32px 24px;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  max-height: 80vh;
  object-fit: contain;
  display: block;
  margin: auto;
  border-radius: 8px;
`;

export const ImageButtonStyled = styled.button`
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
`;

export const StyledGapBox = styled(Box)`
  gap: 12px;
`;

export const ErrorTextStyled = styled.p`
  font-size: 12px;
  margin-top: 2px;
  color: ${({ theme }) => theme.firebrick};
`;
