const paddingLeft = '40px';

export const COLUMNS = [
  {
    id: 'name',
    label: 'Nombre del documento',
    paddingLeft,
    width: 250,
    position: 1,
  },
  {
    id: 'actions',
    paddingLeft: '',
    label: 'Acciones',
    width: 5,
    position: 2,
  },
];

export const errorMessage = 'Ha ocurrido un error, intente nuevamente.';
export const successMessage = 'El documento se ha guardado con éxito.';
export const errorAlert = { status: true, type: 'error', message: errorMessage };
export const successAlert = { status: true, type: 'success', message: successMessage };

export const PRODUCT_ID = {
  MPOS: 'TERMINAL_BILLET',
  LINK: 'LINK_DE_PAGO',
  BILLETAP: 'BILLET_TAP',
};
