/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import CancelIcon from '@material-ui/icons/Cancel';
import searchIcon from '../../../assets/img/iconos/searchIcon.svg';
import newButton from '../../../assets/img/iconos/newButton.svg';
import { CustomModal, CustomTable } from '../../../components';
import TabPanel from './TabPanel';
import { COLUMNS, errorAlert } from '../constants';
import getAllDocuments from '../../../services/documents/getAllDocuments';
import useStyle from '../useStyle';
import DropdownWithFilter from '../../../components/DropDown/DropDown';
import { capitalize, findDocument } from '../ActionForm/utils';

const DocumentsTab = ({
  setAlert, setLoading, tabSelected, documents, setDocuments, screens, setScreens,
}) => {
  const classes = useStyle();
  const [allDocuments, setAllDocuments] = useState([]);
  const [documentsRow, setDocumentsRow] = useState([]);
  const [documentsRowCopy, setDocumentsRowCopy] = useState([]);
  const [showDropDown, setShowDropDown] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [enteredFilter, setEnteredFilter] = useState('');
  const [newDocumentValue, setNewDocumentValue] = useState('');
  const [selectedDocumentId, setSelectedDocumentId] = useState('');

  const filterInfoTable = (filter, codesMapped) => {
    const documentList = codesMapped != null ? codesMapped : documentsRowCopy;
    const filteredList = documentList.filter((document) => (document?.name || '')
      .toString()
      .toLowerCase()
      .includes(filter.toString().toLowerCase()));
    setDocumentsRow(filteredList);
  };

  const toggleDropList = () => {
    setShowDropDown((prev) => !prev);
  };
  const fetchDocuments = async () => {
    setLoading(true);
    const {
      data: { data: documentsData },
      status,
    } = await getAllDocuments();
    if (status !== 200) {
      setAlert(errorAlert);
    } else {
      setAllDocuments(documentsData);
    }
    setLoading(false);
    return documentsData;
  };

  const deleteDocument = (documentId) => {
    setDocuments((prev) => prev.filter((doc) => doc.id !== documentId));
  };
  const confirmDocumentDeletion = (documentId) => {
    if (findDocument(screens, documentId)) {
      setSelectedDocumentId(documentId);
      return setShowModal(true);
    }
    return deleteDocument(documentId);
  };

  const deleteDocumentFromScreens = (documentId) => {
    const newScreens = screens.filter((screen) => {
      const screensCopy = { ...screens };
      screensCopy.documents = screen.documents.filter((doc) => doc.id !== documentId);
      return screensCopy.documents.length > 0;
    });
    setScreens(newScreens);
    deleteDocument(documentId);
  };

  const createActions = (documentId) => (
    <Box display="flex" flexDirection="row" justifyContent="center">
      <IconButton
        edge="end"
        aria-label="Eliminar"
        size="small"
        onClick={() => confirmDocumentDeletion(documentId)}
      >
        <Tooltip title="Eliminar" aria-label="edit" placement="top">
          <DeleteIcon />
        </Tooltip>
      </IconButton>
    </Box>
  );

  const setFilteredDocuments = (documentsParam) => {
    const documentsInfo = documentsParam?.map((document) => ({
      name: capitalize(document.label),
      active: true,
      actions: createActions(document.id),
      id: document.id,
    }));

    setDocumentsRow(documentsInfo);
    setDocumentsRowCopy(documentsInfo);
    if (enteredFilter !== '') {
      filterInfoTable(enteredFilter, documentsInfo);
    }
  };

  useEffect(() => {
    filterInfoTable(enteredFilter, null);
  }, [enteredFilter]);

  useEffect(() => {
    setDocumentsRow([]);
    setFilteredDocuments(documents);
  }, [documents]);

  useEffect(() => {
    fetchDocuments();
  }, []);

  const documentsSelected = documents.map((document) => document.id);
  const dropdownOptions = allDocuments.filter(
    (document) => !documentsSelected.includes(document.id),
  );

  return (
    <TabPanel value={tabSelected} index={0}>
      <div>
        {showDropDown ? (
          <div className={classes.boxContainer}>
            <DropdownWithFilter
              label="Agregar nuevo documento a este producto"
              options={dropdownOptions}
              value={newDocumentValue}
              onChange={(value, id) => {
                setNewDocumentValue(value);
                const newDocument = allDocuments.find((document) => document.id === id);
                setDocuments((prev) => [...prev, { id, label: newDocument.label }]);
                setNewDocumentValue('');
                setShowDropDown(false);
              }}
              className={classes.dropdownContainer}
              isOpenByParent
            />
            <Tooltip title="Cancelar" aria-label="Cancelar">
              <IconButton
                onClick={toggleDropList}
                edge="end"
                size="small"
                classes={{ disabled: classes.disabled }}
              >
                <CancelIcon className={classes.cancelIcon} />
              </IconButton>
            </Tooltip>
          </div>
        ) : (
          <div className={classes.boxContainer}>
            <TextField
              className={classes.searchTextField}
              value={enteredFilter}
              onChange={(e) => setEnteredFilter(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img
                      src={searchIcon}
                      alt="searchIcon"
                      className={classes.searchIcon}
                    />
                  </InputAdornment>
                ),
                disableUnderline: true,
              }}
              placeholder="Buscar documentos asignados a este producto"
            />
            <Tooltip title="Agregar documento" aria-label="Agregar documento">
              <IconButton
                onClick={toggleDropList}
                style={{ fontSize: '20px' }}
                edge="end"
                size="small"
                classes={{ disabled: classes.disabled }}
              >
                <img src={newButton} alt="newButton" />
              </IconButton>
            </Tooltip>
          </div>
        )}
      </div>
      <CustomTable data={documentsRow} columns={COLUMNS} />
      <CustomModal
        firstButtonAction={() => setShowModal(false)}
        isOpen={showModal}
        setIsOpen={setShowModal}
        title="Error al eliminar documento"
        text="Este documento esta asignado a un producto, ¿Estás seguro que deseas eliminarlo?"
        firstButtonLabel="Cancelar"
        secondButtonLabel="Eliminar"
        secondButtonAction={() => deleteDocumentFromScreens(selectedDocumentId)}
      />
    </TabPanel>
  );
};

DocumentsTab.propTypes = {
  tabSelected: PropTypes.number.isRequired,
  setLoading: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  documents: PropTypes.array.isRequired,
  setDocuments: PropTypes.func.isRequired,
  screens: PropTypes.array.isRequired,
  setScreens: PropTypes.func.isRequired,
};

export default DocumentsTab;
